
import React, { useRef, useState, useEffect } from 'react';
import style from './style.module.css';

import SurveyView from '../SurveyView';
import NavigationView from '../ContentView';
import icons from '../../resources/icons';
import conditional from '../../utils/conditional';
import { useEffectGroup } from '../../contexts/AppState';
import surveys from '../../api/surveys';
import date from '../../utils/date';
import { useAppState } from '../../contexts/AppState';
import ListHeader from '../ListHeader';
import surveysV2 from '../../api/surveysV2';
import Admin_NewQuestionView from "../Admin_NewQuestionView";
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../v2/LoaderInline/LoaderInline';

const Admin_SurveyList = props => {

    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState(null);
    const [updates, setUpdates] = useState(null);
    const [question, setQuestion] = useState(null);
    const [ppQuestions, setPPQuestions]=  useState(null);
    const [fetchingQuestions, setFetchingQuestions] = useState(true)

    const groupIdentifier = useRef();

    const fetch = async () => {
        const results = await surveysV2.getQuestions(props.group);
        setFetchingQuestions(false)

        if (!results) {
            console.log('error fetching surveys');
            return;
        }

        setItems(results.reverse());

        groupIdentifier.current = props.group;
    };

    const add = async () => {
        setQuestion({});
        // if (items.some(item => item._id === 'new')) {
        //     select(0);
        //     return;
        // }

        // const message = { _id: 'new' };
        // setItems([message, ...items]);
        // select(0);
    };

    const updateChat = chat => {
        // setTimeout(() => {
        //     console.log('upd', items, chat);
        //     setItems(items => items.map(item => item._id === chat._id ? chat : item));
        // }, 100);
    };

    useEffect( ()=> {
        fetch();
    }, [])

    const actions = [
        // {
        //     icon: icons.add,
        //     perform: () => add(),
        //     // name: 'New Chat',
        //     // primary: true
        // },
        {
            icon: icons.add,
            name: 'PuzzlePrint Questions',
            perform: () => showPuzzlePrintQuestions()
        }
    ];

    const showPuzzlePrintQuestions = async () => {
        const data = await surveysV2.getPPQuestions();
        setPPQuestions(data.ppQuestions);
        add();
    }

    useEffect(() => {
        console.log(ppQuestions)
    }, [ppQuestions])

    const select = index => {
        setSelected(index);
    };

    const addChat = chat => {
        setItems(items => items.map(item => item._id === 'new' ? chat : item));
    };

    const total = item => {
        const answers = item.total; // item.results.reduce((sum, current) => sum + current.count, 0);
        return `${answers} participant${answers === 1 ? '' : 's'}`;
    };

    const onSave = async () => {
        setQuestion(null);
        setItems([])
        setFetchingQuestions(true);
        await fetch();
        setFetchingQuestions(false);
    };

    const categoryPillStyles = {
        engagement: style.engagementPill,
        pulse: style.pulsePill,
        custom: style.customPill,
        puzzleprint: style.puzzleprintPill,
    }

    const handleNewQuestionClose = () => {
        console.log('closed')
    }

    const item = (item, index) => (
        <div className={conditional('item', style, { selected: selected === index })} onClick={() => select(index)}>
            <div className={style.itemContent}>
                {/* <div className={style.itemPhoto} /> */}
                <div className={style.itemInfo}>
                    <div className={style.itemNameContent}>
                        <div className={style.itemName}>
                            <span className={style.itemCount}>
                                {index + 1}. 
                            </span>
                            {
                                item.category && item.category.split(',').map((cat) => (
                                    <span className={categoryPillStyles[cat in categoryPillStyles ? cat : 'puzzleprint']}>
                                        {cat ? cat : 'pulse'}
                                    </span>
                                ))
                            }
                            <span className={style.itemLabel}>
                                {item.label}
                            </span>
                        </div>
                        {/* <div className={style.itemDate}>{item._id !== 'new' ? item.timestamp : ''} - {item.timing}</div> */}
                    </div>
                    {/* Display surveyData meta here */}
                    {/* <div className={style.itemPreview}>{item._id !== 'new' ? total(item) : 'What do you want to ask?'}</div> */}
                </div>
            </div>
        </div>
    );
    
    return (
        <div className={style.SurveyList}>
            <NavigationView primary title="Questions" actions={actions}>
                {fetchingQuestions && <div className='px-5 pb-5'>
                    <Loader text='Loading questions...'/>
                </div>}
                <ListHeader title="New Question" description="Get feedback from your team" action={add} />
                <div className={style.items}>
                    {items && items.map(item)}
                </div>
            </NavigationView>
            <SurveyView group={props.group} data={selected !== null ? items[selected] : null} updates={updates} add={addChat} update={updateChat} onBack={async () => {setSelected(null); await fetch()}} onArchive={async () => {setSelected(null);await fetch()}} />
            <Admin_NewQuestionView ppQuestions={ppQuestions} group={props.group} data={question} onBack={() => {setQuestion(null); setPPQuestions(null)}} onSave={onSave} />

        </div>
    );

};

export default Admin_SurveyList;
