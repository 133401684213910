import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.css';

import NavigationView from '../ContentView';
import ConfirmDialog from '../ConfirmDialog';
import EditOrganizationView from '../EditOrganizationView';
import conditional from '../../utils/conditional';
import icons from '../../resources/icons';
import { useAppState } from '../../contexts/AppState';
import users from '../../api/users';
import groups from '../../api/groups';
import EditUserRoleView from '../EditUserRoleView';
import NewUserRoleView from '../NewUserRoleView';
import SegmentTabs from '../SegmentTabs';
import { useTabSwitcher } from '../../utils/tabSwitcher';
import Admin_SurveyList from '../Admin_SurveyList';
import Admin_AnalyticsView from '../Admin_SurveyAnalyticsView';
import Loader from '../v2/LoaderInline/LoaderInline';


const OrganizationView = props => {

    const [current, setCurrent] = useState(null);
    const [items, setItems] = useState([]);
    const [scroll, setScroll] = useState(null);
    const [showIndicator, setShowIndicator] = useState(false);
    const [dialog, setDialog] = useState(null);
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [add, setAdd] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // New state for pagination
    const itemsPerPage = 10; // Number of items per page
    const [isImporting, setIsImporting] = useState(false);
    const [toImportCount, setToImportCount] = useState(0);
    const [importedCount, setImportedCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true); 

    const [organizationPageTabs, selectedOrganizationPage, setSelectedOrganizationPage, ]= useTabSwitcher('page', style, ['Users', 'Questions', 'Analytics']);

    const { group, setGroup } = useAppState();
    const [selectedGroupId, setSelectedGroupId] = useState('');

    const backIdentifier = useRef(null);
    const previous = useRef(null);

    useEffect(() => {
        if (!props.data) return;
        setCurrent(props.data._id);
        setUser(null);
        setRole(null);
        setAdd(null);
        setSelectedOrganizationPage(0);
        setSelectedGroupId(props.data._id);
    }, [props.data]);

    const fetch = async () => {
        setIsLoading(true);
        const prev = previous.current;
        previous.current = current;
        if (prev === current || prev === 'new') return;

        setItems([]);
        setShowIndicator(false);

        const users = await groups.members(current);

        if (!users) {
            console.log('error fetching users');
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
        setItems(users.sort((a, b) => (a.firstName.toLowerCase() + '' + a.lastName.toLowerCase()).localeCompare(b.firstName.toLowerCase() + '' + b.lastName.toLowerCase())));
    };

    useEffect(() => {
        if (!current) return;
        fetch();
    }, [current]);

    useEffect(() => {
        const messages = props.updates;
        if (!messages || !messages.add.length || !current) return;

        let delay = 1000;
        
        for (const message of messages.add) {
            if (message.chat !== current) continue;
            if (items.some(item => item._id === message.message._id)) continue;

            setTimeout(() => {
                if (message.chat !== current) return;
                setItems(items => [...items, message.message]);
                setScroll(Math.random().toString());
                setShowIndicator(false);
            }, delay);

            delay += 2000;
        }
    }, [props.updates]);

    useEffect(() => {
        if (!items.length) return;
        setScroll(Math.random().toString(36));
        if(items.length > 0) setCurrentPage(1)
    }, [items]);

    const wait = time => new Promise(resolve => setTimeout(() => { resolve() }, time));

    const back = async () => {
        props.onBack();

        const chat = current;
        const identifier = Math.random().toString(36);
        backIdentifier.current = identifier;

        await wait(500);

        if (current !== chat || backIdentifier.current !== identifier) return;
        setCurrent(null);
        previous.current = null;
        
        setUser(null);
        setRole(null);
        setAdd(null);
    };

    const remove = async () => {
        setDialog({
            title: `Delete This Organization?`,
            description: `If you delete, every user will no longer access this organization or its data.`,
            main: {
                title: 'Delete',
                action: async () => {
                    const result = await groups.remove(current);

                    if (!result) {
                        console.log('Error removing organization');
                        return;
                    }

                    const currentChat = current;
                    const identifier = Math.random().toString(36);
                    backIdentifier.current = identifier;

                    await wait(500);
                    props.onFinish && props.onFinish();
                    props.onArchive && props.onArchive();
                    setDialog(null);

                    if (current !== currentChat || backIdentifier.current !== identifier) return;
                    setCurrent(null);
                    previous.current = null;
                }
            },
            secondary: {
                title: 'Cancel',
                action: async () => {
                    setDialog(null);
                }
            }
        });
    };

    const refresh = async () => {
        setIsLoading(true)
        const users = await groups.members(current);

        if (!users) {
            console.log('error fetching users');
            setIsLoading(false)
            return;
        }

        setIsLoading(false);

        setItems(users);
    };

    const onSave = () => {
        setUser(null);
        setRole(null);
        setAdd(null);
        fetch();

        props.onUpdate && props.onUpdate();
        refresh();
    };

    // Pagination Logic
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const currentItems = items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const userRow = (data, index) => (
        <tr onClick={() => setRole({ ...data, group: current })}>
            <td><span className={style.itemPreview}>{((currentPage - 1) * 10) + index + 1}.</span> {data.firstName}</td>
            <td>{data.lastName}</td>
            <td>{data.email}</td>
            {/* <td>{formatPhone(data.phone)}</td> */}
            <td>{(data.groupData || {}).title || ''}</td>
            <td>{data.admin ? 'Admin' : 'Standard'}</td>
        </tr>
    );

    const uploadError = () => {
        alert('The file you uploaded was not valid. Please ensure it has the following fields in the correct order.\nFirst Name, Last Name, Email, Title, Phone');
    };

    const handleUpload = async result => {
        const lines = result.split('\n');
        const first = lines[0];

        const fields = first.split(',');
        if (fields.length !== 5 || fields.map(f => f.split(' ').join('')).join(',').toLowerCase().trim() !== 'firstname,lastname,email,title,phone') return uploadError();

        const results = [];

        for (const line of lines.slice(1)) {
            const values = line.split(',');
            if (values.length !== 5) continue;
            results.push(values);
        }

        if (window.confirm('Found ' + results.length + ' contacts. Ready to import?')) {
            let counter = 0;
            setIsImporting(true);
            setToImportCount(results.length)

            for (const value of results) {
                try {
                    let phone = value[4].trim();

                    const data = {
                        email: value[2].toLowerCase(),
                        phone,
                        firstName: value[0],
                        lastName: value[1],
                        photo: '',
                        thumbnail: '',
                        permissions: 'standard',
                        groupId: current,
                        groupTitle: value[3]
                    };

                    const newUser = await users.add(data);

                    if (newUser && newUser.user && newUser.user._id) {
                        await groups.put(props.data._id, newUser.user._id, {
                            title: value[3]
                        });
                    }
                  
                    setImportedCount((curr) => curr + 1);

                    counter++;
                } catch (e) {
                    console.log('error adding user', e);
                }
            }

            const update = await groups.members(current);
            if (update) setItems(update);

            setDialog({
                title: `Import success`,
                description: `Imported ${counter} out of ${results.length} employees`,
                main: {
                    title: 'Close',
                    action: async () => {
                        setToImportCount(0);
                        setImportedCount(0);
                        setDialog(null);
                    }
                }
            });
           

        }
        setIsImporting(false);
    };

    const batchImport = () => {
        const input = document.createElement('input');
        input.type = 'file';
        
        input.onchange = e => {
            const file = e.target.files[0];
            if (!file) return;

            const reader = new FileReader();

            reader.onload = e => {
                if (e.target.readyState !== 2) return;
                if (e.target.error) return;
                handleUpload(e.target.result);
            }

            reader.readAsText(file);
        };

        input.click();
    };

    const clearOrganization = () => {
        setDialog({
            title: `Clear this organization?`,
            description: `This will also remove the employees from the user list.`,
            main: {
                title: 'Delete',
                action: async () => {
                    setIsLoading(true);
                    const result = await groups.clear(current);

                    console.log(result)

                    if (!result) {
                        console.log('Error clearing organization');
                        return;
                    }

                   refresh();

                    refresh()
                    await wait(500);
                    props.onFinish && props.onFinish();
                    setDialog(null);
                    setIsLoading(false);
                }
            },
            secondary: {
                title: 'Cancel',
                action: async () => {
                    setDialog(null);
                }
            }
        });
    }


    const organizationViewClass = conditional('OrganizationView', style, { visible: props.data !== null });
    const surveysClass = conditional('surveys', style, { visible: props.data !== null });
    const indicatorClass = conditional('indicator', style, { visible: showIndicator });

    const actions = [
        {
            icon: icons.remove,
            name: "Clear organization",
            perform: () => clearOrganization()
        },
        {
            icon: icons.upload,
            perform: () => batchImport()
        },
        {
            icon: icons.edit,
            perform: () => setUser(props.data)
        },
        {
            icon: icons.add,
            perform: () => setAdd({ group: current })
        }
    ];
    return (
        <div className={organizationViewClass}>
          {
            isLoading ? <div className='p-[50px]'><Loader text="Loading organization data..."/></div> : <>
                <NavigationView secondary title={props.data.name} bottom scroll={scroll} onBack={back} actions={actions}>
                <div className={style.organizationSegmentTabs}>
                    <SegmentTabs selectedTab={selectedOrganizationPage} tabs={organizationPageTabs} onSelect={index => { setSelectedOrganizationPage(index)}} />   
                </div>

                {selectedOrganizationPage === 0 && 
                <>
                    <div className={style.info}>
                        <div className={style.infoWrapper}>
                            <div className={style.infoIcon} style={{ backgroundImage: props.data.public ? icons.publicSelected : icons.privateSelected }} />
                            <div className={style.infoText}>{props.data.public ? 'Public organization' : 'Private organization'}</div>
                        </div>
                        —
                        {
                            isImporting ? <div className='text-xs flex items-center gap-2'>
                            <div className='animate-spin duration-100 w-3 h-3 border-t-[2px] border-l-[2px] border-lime-600 border-solid rounded-full'></div>
                            Importing {importedCount} out of {toImportCount}
                            </div> : <div className={style.infoText}>{items.length} Employee{items.length > 1 && 's'}</div>
                        }
                    
                    </div>
                    {
                        !isImporting && <div className={style.items}>
                            <div className={surveysClass}>
                                <div className={style.sectionContent}>
                                    <table className={style.table}>
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                {/* <th>Phone</th> */}
                                                <th>Title</th>
                                                <th>Permissions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentItems.map((data, index) => userRow(data, index))} {/* Only current page items */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Pagination Controls */}
                            <div className={style.pagination}>
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className={style.defaultPage}
                                >
                                    Previous
                                </button>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(index + 1)}
                                        className={currentPage === index + 1 ? style.activePage : style.defaultPage}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className={style.defaultPage}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    }
                </>
                }

                {selectedOrganizationPage === 1 && <div className={style.sectionContent}>
                    <Admin_SurveyList group={selectedGroupId}/>
                </div>}

                {selectedOrganizationPage === 2 &&  <Admin_AnalyticsView group={selectedGroupId}/>}

                </NavigationView>
                <ConfirmDialog data={dialog} />
                <EditOrganizationView data={user} onBack={() => setUser(null)} onSave={onSave} />
                <EditUserRoleView data={role} onBack={() => setRole(null)} onSave={onSave} />
                <NewUserRoleView data={add} onBack={() => setAdd(null)} onSave={onSave} />
            </>
          }
        </div>
    );
};

export default OrganizationView;
