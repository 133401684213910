
import React, { useState, useRef, useEffect } from 'react';
import style from './style.module.css';
import icons, { awardIcon } from '../../resources/icons';

import NavigationView from '../ContentView';
import ConfirmDialog from '../ConfirmDialog';
import SegmentTabs from '../SegmentTabs';
import { useAppState, useEffectGroup } from '../../contexts/AppState';
import { useTabSwitcher } from '../../utils/tabSwitcher';
import groups from '../../api/groups';
import badges from '../../api/badges';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import AwardView from '../AwardView';
import surveys from '../../api/surveys';
import surveysV2 from '../../api/surveysV2';
import UTCClock from "../UTCClock/UTCClock"
import CountdownToMidnight from "../UTCClock/CountdownToMidnight"
import dayjs from "dayjs"



const repeats = [
    {
        title: 'None',
        type: 'none',
        count: 1
    },
    {
        title: 'Weekly',
        type: 'weekly',
        count: 260
    },
    {
        title: 'Monthly',
        type: 'monthly',
        count: 72
    },
    {
        title: 'Yearly',
        type: 'yearly',
        count: 5
    }
];

const actions = [];

const NewQuestionView = props => {

    const { auth } = useAppState();
    const [current, setCurrent] = useState(null);
    
    // const [badges, setBadges] = useState([]);
    // const [fives, setFives] = useState([]);

    const [awards, setAwards] = useState([]);
    const [earned, setEarned] = useState([]);
    const [dialog, setDialog] = useState(null);
    const [localGroupId, setLocalGroupId] = useState('');
    const [scheduleDate, setScheduleDate] = useState(null);

    const defaultSelectedPPQuestions = [
        [0,1,2,3],
        [0,1,2,3],
        [0,1,2,3,4,5],
        [0,1,2,3,4],
        [0,1,2,3]
    ]
    const [selectedPPQuestions, setSelectedPPQuestions] = useState(defaultSelectedPPQuestions);

    const [question, setQuestion] = useState('');
    // const [answers, setAnswers] = useState(['', '']);
    const [answers, setAnswers] = useState(['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']);

    const [timingTabs, selectedTiming, setSelectedTiming, timingViews] = useTabSwitcher('page', style, ['Send Once', 'Daily', 'Weekly', 'Monthly', 'Yearly']);
    const [countLabel, setCountLabel] = useState('days');
    const [periodCount, setPeriodCount] = useState(1);
    const [categoryTabs, selectedCategory, setSelectedCategory, categoryViews] = useTabSwitcher('page', style, ['Pulse', 'Engagement', 'Custom']);
    const [scheduleRepeat, setScheduleRepeat] = useSmoothState(0, 0.5);

    const [startDate, setStartDate] = useState(new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()) + 1).toISOString().split("T")[0]);
    const [minStartDate, setMinStartDate] = useState(new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 1)).toISOString().split("T")[0]);
    const [minEndDate, setMinEndDate] = useState(new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 2)).toISOString().split("T")[0]);
    const [endDate, setEndDate] = useState(new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 2)).toISOString().split("T")[0]);

    const timing = () => {
        const times = {
            0: 'once',
            1: 'daily',
            2: 'weekly',
            3: 'monthly',
            4: 'yearly'
        };

        return times[selectedTiming] || 'daily';
    };


    const calculateNextDate = (startDate, timing = 'daily', periodDifference = 1) => {
        const date = new Date(startDate); // Parse the input date
      
        switch (timing.toLowerCase()) {
        case "once": 
        return minEndDate;
          case "daily":
            date.setDate(date.getDate() + (1 * periodDifference));
            break;
          case "weekly":
            date.setDate(date.getDate() + (7 * periodDifference) );
            break;
          case "monthly":
            date.setMonth(date.getMonth() + (1 * periodDifference) );
            break;
          case "yearly":
            date.setFullYear(date.getFullYear() + (1 * periodDifference));
            break;
          default:
            throw new Error("Invalid timing value. Use 'daily', 'weekly', 'monthly', or 'yearly'.");
        }
      
        // Format the date as YYYY-MM-DD
        return date.toISOString().split("T")[0];
      };

    useEffect(() => {
        setEndDate(calculateNextDate(startDate, timing()));
        setPeriodCount(1)
        if(timing() === "daily"){
            setCountLabel("day/s")
        }
        else if(timing() === "weekly"){
            setCountLabel("week/s")
        }
        else if(timing() === "monthly"){
            setCountLabel("month/s")
        }
        else if(timing() === "yearly"){
            setCountLabel("year/s")
        }
    }, [startDate, selectedTiming,])

    useEffect(() => {
        setEndDate(calculateNextDate(startDate, timing(), periodCount));
    }, [periodCount])


    const backIdentifier = useRef(null);
    const previous = useRef(null);
    const groupRef = useRef(null);

    useEffect(() => {
        setLocalGroupId(props.group)
    }, [props.group])

    const fetch = async (group, reload = true) => {
        groupRef.current = localGroupId;
    };
    
    const fetchAwards = async () => {
        const [awarded, badged] = await Promise.all([
            badges.awarded(localGroupId, current._id),
            badges.earned(localGroupId, current._id)
        ]);

        if (!awarded || !badged) {
            console.log('error fetching awards');
            return;
        }
        
        setAwards(awarded);
        setEarned(badged);
    };


    useEffectGroup(fetch); // for badges

    useEffect(() => {
        if (!props.data) return;
        setCurrent(props.data);
        console.log(props.data);

        const identifier = Math.random().toString(36);
        backIdentifier.current = identifier;

        fetch(localGroupId);
    }, [props.data]);

    const wait = time => new Promise(resolve => setTimeout(() => { resolve() }, time));

    const back = async () => {
        setSelectedPPQuestions(defaultSelectedPPQuestions)
        props.onBack();

        const profile = current;
        const identifier = Math.random().toString(36);
        backIdentifier.current = identifier;

        await wait(500);

        if ((current && profile && current._id !== profile._id) || backIdentifier.current !== identifier) return;
        setCurrent(null);
        previous.current = null;

        setQuestion('');
        setAnswers(['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']);
        // setAnswers(['', '']);
    };


    const category = () => {
        const categories = {
            0: 'pulse',
            1: 'engagement',
            2: 'custom'
        };
        return categories[selectedCategory] || 'pulse';
    };

    const save = async () => {
        if (!props.ppQuestions && question.length < 1) {
            console.log('You need to enter a question first.')
            return;
        }

        let result = null;
        if(props.ppQuestions){

            const questionData = {
                timing: timing(),
                timestamp: new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
                groupId: localGroupId,
                startDate: timing() !== 'once' ? dayjs(startDate).format('MM/DD/YYYY') : 'null',
                endDate: timing() !== 'once' ? dayjs(endDate).format('MM/DD/YYYY') : 'null',
                ppQuestionIndices: selectedPPQuestions
            }
    
            result = await surveysV2.importPP(questionData);

        }else{  
            const questionData = {
                label: question,
                timing: timing(),
                category: category(),
                timestamp: new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
                groupId: localGroupId,
                startDate: timing() !== 'once' ? dayjs(startDate).format('MM/DD/YYYY') : 'null',
                endDate: timing() !== 'once' ? dayjs(endDate).format('MM/DD/YYYY') : 'null',
            }
    
            result = await surveysV2.createQuestion(questionData);
        }
        
        props.onSave();

        console.log(result)
        const profile = current;
        const identifier = Math.random().toString(36);
        backIdentifier.current = identifier;
        await wait(500);
        if ((current && profile && current._id !== profile._id) || backIdentifier.current !== identifier) return;
        setCurrent(null);
        previous.current = null;
        setQuestion('');
        setSelectedPPQuestions(defaultSelectedPPQuestions)
    };

    const user = () => {
        if (!auth || !auth.user) return {};
        return auth.user;
    };

    const data = () => {
        const groups = user().groups || [];
        if (!groups.length) return {};

        const active = groups.filter(data => data._id === localGroupId)[0];
        if (!active) return {};

        return active;
    };

    const finish = () => {
        fetchAwards();
        setDialog(null);
    };

    const updateAnswer = selected => e => {
        const updated = answers.map((current, index) => index === selected ? e.target.value : current);
        setAnswers(updated);
    };

    const addAnswer = () => {
        setAnswers(answers => [...answers, '']);
    };

    const remove = selected => {
        setAnswers(answers => answers.filter((_, index) => index !== selected));
    };

    const award = (item, index) => (
        <div className={conditional('awardItem', style, { selected: false })}>
            <div className={style.awardItemContent}>
                <div className={style.awardItemPhoto} style={{ backgroundImage: awardIcon(item.name) }} />
                <div className={style.awardItemInfo}>
                    <div className={style.awardItemNameContent}>
                        <div className={style.awardItemName}>{item.name}</div>
                        <div className={style.awardItemDate}>{item.count}x</div>
                    </div>
                    {/* <div className={style.awardItemPreview}>{item.secondaryInfo.description || 'Serving customers'}</div> */}
                </div>
            </div>
        </div>
    );

    const badge = (item, index) => (
        <div className={conditional('badgeItem', style, { selected: false })}>
            <div className={style.badgeItemContent}>
                <div className={style.badgeItemPhoto} style={{ backgroundImage: awardIcon(item.name) }} />
                <div className={style.badgeItemInfo}>
                    <div className={style.badgeItemNameContent}>
                        <div className={style.badgeItemName}>{item.name}</div>
                        {/* <div className={style.badgeItemDate}>{(item.secondaryInfo || {}).description}</div> */}
                    </div>
                    <div className={style.badgeItemPreview}>{(item.secondaryInfo || {}).description}</div>
                </div>
            </div>
        </div>
    );

    const answerInput = (answer, index) => (
        <div className={style.answerInput}>
            <input
                className={style.answer}
                value={answer}
                onChange={updateAnswer(index)}
                placeholder="Answer" /> 
            <div className={style.answerRemove} style={{ backgroundImage: icons.remove }} onClick={() => remove(index)} />
        </div>
    );

    const newQuestionViewClass = conditional('NewQuestionView', style, { visible: props.data });
    
    const profileUser = current ? current : (props.root ? user() : {});
    const profileData = current ? { ...data(), data: current.groupData } : (props.root ? data() : {});

    useEffect(() => {
        console.log(props.ppQuestions)
    })

    const updateSelectedPPQuestions = (catIdx, qIdx) => {
        const updatedSelectedPPQuestions = [...selectedPPQuestions];
        if(selectedPPQuestions[catIdx].includes(qIdx)){
            const newCatValue = selectedPPQuestions[catIdx].filter((i) => i !== qIdx);
            updatedSelectedPPQuestions[catIdx] = newCatValue.sort();
            setSelectedPPQuestions(updatedSelectedPPQuestions);
        }else{
            updatedSelectedPPQuestions[catIdx].push(qIdx)
            updatedSelectedPPQuestions[catIdx] = updatedSelectedPPQuestions[catIdx].sort();
            setSelectedPPQuestions(updatedSelectedPPQuestions);
        }
    }


    return (
        <div className={newQuestionViewClass}>
            <div className={style.container}>
                <NavigationView title={props.ppQuestions ? "Import PuzzlePrint Questions" : "New Question"} modal onClose={!props.root ? back : null} hideHeader persistTitle actions={actions}>
                   
                    {
                        !props.ppQuestions && 
                        <div className={style.profile}>
                            <textarea 
                                className={style.message}
                                placeholder={'Ask a question'}
                                maxLength="250"
                                value={question}
                                onChange={e => setQuestion(e.target.value)}
                            />
                        </div>
                    }

                    {
                        props.ppQuestions && <div className={style.section}>
                            {
                                props.ppQuestions.map((item, catIdx) => <div>
                                    <p className={style.sectionTitle}>{item.categoryName}</p>
                                    {
                                        item.questions.map((q, qIdx) => <div className={style.ppQuestionItem}>
                                        <input checked={selectedPPQuestions[catIdx].includes(qIdx)} onChange={() => updateSelectedPPQuestions(catIdx, qIdx)} id={`item-${catIdx}-${qIdx}`} type="checkbox"></input>
                                        <label for={`item-${catIdx}-${qIdx}`} className={style.ppQuestionItemParagraph}>
                                            {q}
                                        </label>
                                        </div>)
                                    }
                                </div>)
                            }
                        </div>
                    }
                    
                    <div className={style.section}>
                        <div className={style.sectionTitle}>Repeat</div>
                        <SegmentTabs selectedTab={selectedTiming} tabs={timingTabs} onSelect={setSelectedTiming} />
        
                        {!props.ppQuestions &&
                        <div className={style.section}>
                            <div className={style.sectionTitle}>Category</div>
                            <SegmentTabs selectedTab={selectedCategory} tabs={categoryTabs} onSelect={setSelectedCategory} />
                        </div>}


                        {
                            selectedTiming !== undefined && selectedTiming !== 0 && (<>
                                <div className={style.infoBox}>
                                    <div className={style.info}>
                                        <div className={style.sectionTitle}>EST Time</div>
                                        <UTCClock/>
                                    </div>

                                    <div className={style.info}>
                                        <div className={style.sectionTitle}>Next batch trigger</div>
                                        <CountdownToMidnight/>
                                    </div>
                                </div>
                                <div className={style.dateGroup}>
                                    <div className={style.sectionTitle}>Start Date</div>
                                    <div className={style.datePicker}>
                                        <input defaultValue={minStartDate} min={minStartDate} className={style.date} type="date" onChange={e => setStartDate(e.target.value)} />
                                        <div className={style.dateIcon} style={{ backgroundImage: icons.calendar }}></div>
                                    </div>
                                </div>

                                <div className={style.dateGroup}>
                                    <div className={style.sectionTitle}>Number of {countLabel}</div>
                                    <div className={style.datePicker}>
                                        <input className={style.periodCount} onChange={(e) => setPeriodCount(e.target.value)} value={periodCount} type="number" />
                                    </div>
                                </div>

                                <div className={style.dateGroup}>
                                    <div className={style.sectionTitle}>End Date</div>
                                    <div className={style.datePicker}>
                                        <input defaultValue={minEndDate} value={endDate} min={minEndDate} disabled className={style.date} type="date" onChange={e => setEndDate(e.target.value)} />
                                        <div className={style.dateIcon} style={{ backgroundImage: icons.calendar }}></div>
                                    </div>
                                </div>
                            </>)
                        }
     
                    </div>
                    <div style={{height: '20px'}} />
                </NavigationView>
            </div>
            <div className={style.sectionContent} style={{ paddingLeft: '0px', paddingRight: '0px', background:'white', width: '100%', maxWidth:'600px', borderRadius: '16px', paddingBottom: '20px' }}>
                <div className={style.surveyActions}>
                    <div className={style.surveyAction + ' ' + style.secondary} onClick={back}>Cancel</div>
                    <div className={style.surveyAction} onClick={save}>{props.ppQuestions ? 'Import Selected Questions' : 'Create'}</div>
                </div>
            </div>
        </div>
    );

};

export default NewQuestionView;
