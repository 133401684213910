import React, { useState, useEffect } from 'react';

const CountdownToMidnight = () => {

  const calculateTimeUntilMidnight = () => {
    const now = new Date();

    // Convert 'now' to EST time using the correct timezone handling
    const estNow = new Date(
        now.toLocaleString("en-US", { timeZone: "America/New_York" })
    );

    // Set EST midnight for today (midnight of the next day)
    const estMidnight = new Date(estNow);
    estMidnight.setHours(24, 0, 0, 0); // Midnight of the next day

    // Calculate the difference in milliseconds
    const timeDiff = estMidnight - estNow;

    // Convert milliseconds into hours, minutes, and seconds
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

  const [timeLeft, setTimeLeft] = useState(calculateTimeUntilMidnight());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeUntilMidnight());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <span>{timeLeft}</span>;
};

export default CountdownToMidnight;
