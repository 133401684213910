import React, { useRef, useState, useEffect } from 'react';
import style from './style.module.css';

import NavigationView from '../ContentView';
import icons from '../../resources/icons';
import conditional from '../../utils/conditional';
import { useEffectGroup } from '../../contexts/AppState';
import users from '../../api/users';
import date from '../../utils/date';
import { useAppState } from '../../contexts/AppState';
import ListHeader from '../ListHeader';
import UserView from '../UserView';
import NewUserView from '../NewUserView';
import Loader from "../v2/LoaderInline/LoaderInline"

const UserList = props => {
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState(null);
    const [updates, setUpdates] = useState(null);
    const [searchedItems, setSearchedItems] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [user, setUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // New state for current page
    const itemsPerPage = 20; // Adjust this to set items per page

    const groupIdentifier = useRef();
    const { group } = useAppState();

    const fetchAllUsers = async group => {
        setIsLoading(true);
        const results = await users.list();

        if (!results) {
            console.log('error fetching users');
            setIsLoading(false);
            return;
        }

        setItems(results.sort((a, b) => (a.firstName.toLowerCase() + '' + a.lastName.toLowerCase()).localeCompare(b.firstName.toLowerCase() + '' + b.lastName.toLowerCase()))); 
        groupIdentifier.current = group;
        setIsLoading(false);
    };

    const add = async () => {
        setUser({});
    };

    const updateChat = chat => {
        // Logic to update chat
    };

    const onSave = () => {
        setUser(null);
        fetchAllUsers(group);
    };

    const select = index => {
        setSelected(index);
    };

    const addChat = chat => {
        setItems(items => items.map(item => item._id === 'new' ? chat : item));
    };

    const updateSearch = value => {
        setSearch(value);
        if (!value.length) {
            setSearchedItems([]);
        } else {
            const filteredSearchItems = items.filter(item =>
                (item.firstName + '' + item.lastName)
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
            setSelected(null)
            setSearchedItems(filteredSearchItems);
            if(filteredSearchItems.length){
                setCurrentPage(1)
            }
        }
    };

    // Pagination Logic
    const allItems = search.length ? searchedItems : items;
    const totalPages = Math.ceil(allItems.length / itemsPerPage);
    const currentItems = allItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (page) => {
        setSelected(null)
        setCurrentPage(page);
    };

    useEffectGroup(fetchAllUsers);

    const actions = [
        {
            name: "Sync",
            perform: () => fetchAllUsers()
        },
        {
            icon: icons.add,
            perform: () => add(),
        },

    ];

    const item = (item, index) => (
        <div className={conditional('item', style, { selected: selected === index })} onClick={() => select(index)}>
            <div className={style.itemContent}>
                <div className={style.itemInfo}>
                    <div className={style.itemNameContent}>
                        <div className={style.itemName}><span className={style.itemPreview}>{((currentPage - 1) * 10) + index + 1}.</span> {item.firstName} {item.lastName}  <span className={style.emailMuted}>({item.email})</span></div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={style.UserList}>
            <NavigationView primary title="Users" actions={actions}>
                <ListHeader title="New User" description="Start onboarding someone" action={add} />

                {isLoading ? <div className='px-5'><Loader text='Loading list of users...'/></div> : <>
                    <input
                        className={style.input}
                        placeholder={`Search ${items.length} user${items.length > 1 && 's'}...`}
                        onChange={e => updateSearch(e.target.value)}
                    />
                    <div className={style.items}>
                        {currentItems.map(item)}
                    </div>
                    <div className={style.pagination}>
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className={style.defaultPage}
                        >
                            Previous
                        </button>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={currentPage === index + 1 ? style.activePage : style.defaultPage}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className={style.defaultPage}
                        >
                            Next
                        </button>
                    </div>
                </>}

               

            </NavigationView>
            {selected !== null && 
                <UserView
                    data={currentItems[selected]}
                    updates={updates}
                    add={addChat}
                    update={updateChat}
                    onBack={() => setSelected(null)}
                    onArchive={() => { setSelected(null); fetchAllUsers(group) }}
                    onUpdate={() => { fetchAllUsers(group) }}
                />
            }
           
            <NewUserView data={user} onBack={() => setUser(null)} onSave={onSave} />
        </div>
    );
};

export default UserList;
