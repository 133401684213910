import NavigationView from '../ContentView';
import style from './style.module.css';

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from 'react';
import surveysV2 from '../../api/surveysV2';
import Loader from '../v2/LoaderInline/LoaderInline';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);


const Admin_AnalyticsView = props => {

  const [chartsData, setChartsData] = useState();
  const [isFetching, setIsFetching] = useState(true);

  const fetch = async () => {
    setIsFetching(true)
    const result = await surveysV2.getSurveyAnalytics(props.group)
    setIsFetching(false)
    setChartsData(result)
  }

  useEffect(() => {
    fetch();
  }, [])

  return <div className={style.SurveyAnalyticsView}>
     <NavigationView title="" >

      {isFetching && <div className='pb-5 pl-5'>
        <Loader text="Loading Graphs..."/>
      </div>}
      
      <div className={style.graphsContainer}>
      {chartsData && chartsData.map((chart) => <div className={style.chartWrapper}>
      <p className="text-md">{chart.questionLabel}</p>
        <Line
        data={{
          labels: chart.data.labels && chart.data.labels.length > 10 ? chart.data.labels.slice(-10) : chart.data.labels,
          datasets: [
            {
              label: "Average Score",
              data: chart.data.data && chart.data.data.length > 10 ? chart.data.data.slice(-10) : chart.data.data,
              backgroundColor: "#ecfccb4d",
              borderColor: "#65a30d",
              borderWidth: 1,
              pointRadius: 7,            // Makes the plot points larger
              pointHoverRadius: 8,       // Increases the point size on hover
              pointBorderWidth: 2,   
              tooltip: {
                callbacks: {
                    label: function(tooltipItem) {
                        const index = tooltipItem.dataIndex;
                        const averageScore = tooltipItem.raw;
                        const respondents = chart.data.respondentsCount ? chart.data.respondentsCount[index] : "N/A";
                        return [`Average Score: ${averageScore.toFixed(2)}`, `No. of Respondents: ${respondents}`];
                    }
                }
              }
            },
          ],
          
        }}
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            labels: {
              font: {
                size: 10,
              },
              padding: 20,
              boxWidth: 10,
            },
          },
          datalabels: {
            display: false,
            anchor: "end", // positions label at the end of the bar
            align: "end", // aligns the label to the top of the bar
            offset: -20,
            formatter: (value) => value.toFixed(2), // formats label to show the value
            color: "black", // label color
            font: {
              weight: "normal",
              size: 10,
            },
          },
        },
        layout: {
          padding: {
            top: 0,
          },
        },
        scales: {
          y: {
              beginAtZero: true,           // Ensures the scale starts from 0
              min: 0,                      // Explicitly sets the minimum value
              max: 5,                      // Explicitly sets the maximum value
              ticks: {
                  stepSize: 1,             // Makes the y-axis increment by 1
                  callback: function(value) {
                      return value;        // Displays the value as is without additional formatting
                  }
              }
          }
      }
        }}
      /></div>
      )}
      
      </div>
     
     </NavigationView>
  </div>
}

export default Admin_AnalyticsView;