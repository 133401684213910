
import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.css';

import NavigationView from '../ContentView';
import conditional from '../../utils/conditional';
import icons from '../../resources/icons';
import ProfileView from '../ProfileView';
import ConfirmDialog from '../ConfirmDialog';
import { useAppState } from '../../contexts/AppState';
import surveys from '../../api/surveys';
import date from '../../utils/date';
import axios from 'axios';
import surveysV2 from '../../api/surveysV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarCheck, faCalendarPlus, faCalendarWeek, faClock, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(relativeTime);


const SurveyView = props => {

    const [current, setCurrent] = useState(null);
    const [question, setQuestion] = useState(null);
    const [timeData, setTimeData] = useState([]);
    const [timeFields, setTimeFields] = useState([]);
    const [dialog, setDialog] = useState(null);
    const [extendDate, setExtendDate] = useState(null);

    const [surveyItem, setSurveyItem] = useState();
    const [itemTimestamps, setItemTimestamps] = useState(null);

    const [scroll, setScroll] = useState(null);
    const [showIndicator, setShowIndicator] = useState(false);

    const { group, setGroup } = useAppState();

    const backIdentifier = useRef(null);
    const previous = useRef(null);

    useEffect(() => {
        setSurveyItem(props.data)
    }, [props.data])

    const wait = time => new Promise(resolve => setTimeout(() => { resolve() }, time));

    const back = async () => {
        props.onBack();

        const chat = current;
        const identifier = Math.random().toString(36);
        backIdentifier.current = identifier;

        await wait(500);

        if (current !== chat || backIdentifier.current !== identifier) return;
        setCurrent(null);
        previous.current = null;

        setQuestion(null);
        setTimeData([]);
        setTimeFields([]);
    };

    const remove = async () => {
        setDialog({
            title: `Delete This Question?`,
            description: `If you delete, users will no longer get prompted to answer this question.`,
            main: {
                title: 'Delete',
                action: async () => {
                    const surveyItemId = surveyItem._id;
                    let groupId = props.group;

                    const result = await surveysV2.deleteQuestion(groupId, surveyItemId);
                    
                    if (!result) {
                        console.log('Error removing survey');
                        return;
                    }

                    const currentChat = current;
                    const identifier = Math.random().toString(36);
                    backIdentifier.current = identifier;

                    await wait(500);
                    props.onFinish && props.onFinish();
                    props.onArchive && props.onArchive();
                    setDialog(null);

                    if (current !== currentChat || backIdentifier.current !== identifier) return;
                    setCurrent(null);
                    previous.current = null;
                }
            },
            secondary: {
                title: 'Cancel',
                action: async () => {
                    setDialog(null);
                }
            }
        });
    };

    const actions = [
        {
            icon: icons.archive,
            perform: remove
        }
    ];

    const updateEndDate = async () => {
        const surveyItemId = surveyItem._id;
        let groupId = props.group;

        await surveysV2.updateEndDate(groupId, surveyItemId, extendDate);
        back();
    }

    const lines = message => message.split('\n').map((line, index) => <div className={style.itemPreview} style={{ marginTop: index ? '5px' : '0px' }}>{line}</div>);
    
    const item = (item, index) => (
        <div className={conditional('item', style, { })}>
            <div className={style.itemContent}>
                <div className={style.itemPhoto} style={{ backgroundImage: item.user ? `url(${item.user.thumbnail})` : 'url(https://cdn2.hubspot.net/hub/6444014/hubfs/PuzzleHR_October2019/images/Puzzle_favicon-150x150.png?width=108&height=108)', backgroundSize: !item.user ? '70% 70%': 'cover' }} />
                <div className={style.itemInfo}>
                    <div className={style.itemNameContent}>
                        <div className={style.itemName}>{item.user ? (item.user.firstName + ' ' + item.user.lastName) : 'Harley'}</div>
                        <div className={style.itemDate}>{date.format(item.date)}</div>
                    </div>
                    {lines(item.message)}
                </div>
            </div>
        </div>
    );

    const percentage = item => {
        if (!props.data || !props.data.results || !props.data.results.length) return '0%';

        const total = props.data.results.reduce((sum, result) => sum + result.count, 0);
        if (!total) return '0%';

        const percent = (item.count / total) * 100;
        return `${parseInt(percent)}%`;
    };

    const max = () => {
        if (!props.data || !props.data.results || !props.data.results.length) return null;
        
        let max = 0;
        let result = null;

        for (const data of props.data.results) {
            if (data.count > max) {
                max = data.count;
                result = data;
            }
        }

        return result ? result.index : null;
    };

    const timeSeries = () => {
        if (!question) return [];

        const results = question.results;
        console.log('rest', results);
    };

    const card = item => {
        const surveyCardClass = conditional('surveyCard', style, { highlighted: max() === item.index });

        return (
            <div className={surveyCardClass}>
                <div className={style.surveyHeader}>
                    <div className={style.featuredInfo}>
                        <div className={style.featuredTitle}>{item.content}</div>
                        <div className={style.featuredSubtitle}>{item.count} response{item.count !== 1 ? 's' : ''}</div>
                    </div>
                    <div className={style.featuredNumber}>{percentage(item)}</div>
                </div>
            </div>
        );
    };

    const resultItem = (item, index) => (
        <div className={conditional('resultItem', style, { selected: false })}>
            <div className={style.resultItemContent}>
                <div className={style.resultItemPhoto} style={{ backgroundImage: '' }} />
                <div className={style.resultItemInfo}>
                    <div className={style.resultItemNameContent}>
                        <div className={style.resultItemName}>{item.name}</div>
                        <div className={style.resultItemDate}>{item.count}x</div>
                    </div>
                    {/* <div className={style.resultItemPreview}>{item.secondaryInfo.description || 'Serving customers'}</div> */}
                </div>
            </div>
        </div>
    );
    
    const surveyViewClass = conditional('SurveyView', style, { visible: props.data !== null });
    const surveysClass = conditional('surveys', style, { visible: true });
    const indicatorClass = conditional('indicator', style, { visible: showIndicator });

    useEffect(() => {
        if(!surveyItem || !surveyItem.responses) return;
        setItemTimestamps(Object.keys(surveyItem.responses))
    },  [surveyItem])

    if (!surveyItem) return <div className={surveyViewClass} />;
    
    return (
        <div className={surveyViewClass}>
            <NavigationView secondary title={surveyItem.label} bottom scroll={scroll} onBack={back} actions={actions}>
                <div className={style.info}>
                    <div className={style.iconWrapper}>
                        <FontAwesomeIcon className={style.greyIcon} icon={faClock}/> <div className={style.infoText}>{'Repeats ' + surveyItem.timing}</div>
                    </div>
                    <div onClick={remove} className={style.deleteQuestion}><FontAwesomeIcon icon={faTrashAlt}/> Delete Question</div>
                </div>
                {
                    (surveyItem.startDate !== 'null' || surveyItem.endDate !== 'null') && 
                    <>
                    <div className={style.info}>
                        <div className={style.iconWrapper}>
                            <FontAwesomeIcon className={style.greyIcon}icon={faCalendar}/> <div className={style.infoText}>{surveyItem.startDate} — {surveyItem.endDate}</div>
                        </div>
                    </div>
                    <div className={style.info}>
                        <div className={style.iconWrapper}>
                            {
                                new Date(surveyItem.nextRun) > new Date(surveyItem.endDate) ?
                                <><FontAwesomeIcon className={style.greenIcon}icon={faCalendarCheck}/> <div className={style.greenText}>Run complete</div></> :
                                <><FontAwesomeIcon className={style.greyIcon}icon={faCalendarWeek}/> <div className={style.infoText}>Next Run: {surveyItem.nextRun}</div></>
                            }      
                        </div>
                    </div>
                    </>
                }
                {
                      (surveyItem.startDate !== 'null' || surveyItem.endDate !== 'null') && <>
                        <div className={style.info}>
                            {
                                new Date(surveyItem.nextRun) < new Date(surveyItem.endDate) && <>
                                    <div className={style.extendDateWrapper}>
                                        <FontAwesomeIcon className={style.greyIcon} icon={faCalendarPlus}/>
                                        <div className={style.infoText}>Extend until: </div>
                                    </div>
                                    <input onChange={(e) => setExtendDate(dayjs(e.target.value).format('MM/DD/YYYY'))} min={new Date(new Date(surveyItem.endDate).setDate(new Date(surveyItem.endDate).getDate() + 2)).toISOString().split("T")[0]} className={style.extendDateInput} type="date"/>
                                    {
                                        extendDate !== null &&  <div onClick={updateEndDate} className={style.saveExtendDateButton}>Save</div>
                                    }
                                   
                                </>
                            }
                        </div>
                      </>
                }

              
                <div className={style.items}>
                    <div className={style.section}>
                        <div className={style.sectionContent}>
                            <table className={style.table}>
                                <thead>
                                    <td>Period</td>
                                    <td># of Respondents</td>
                                    <td>Period Score</td>
                                </thead>
                                {
                                    itemTimestamps &&
                                    <tbody>
                                        {itemTimestamps && itemTimestamps.map((timestamp, index) => <tr>
                                            <td>{timestamp}</td>
                                            <td>{surveyItem.responses[itemTimestamps[index]] &&
                                                surveyItem.responses[itemTimestamps[index]].length}</td>
                                            <td>{surveyItem &&
                                                surveyItem.responses[itemTimestamps[index]] &&
                                                surveyItem.responses[itemTimestamps[index]].length &&
                                                (surveyItem.responses[itemTimestamps[index]].map((i) => Number(i.value)).reduce((a, c) => a + c, 0) / surveyItem.responses[itemTimestamps[index]].length).toFixed(2)
                                            }</td>
                                        </tr>)}
                                    </tbody>
                                }
                              
                            </table>
                        </div>
                    </div>
                    <div style={{height: '16px'}} />
                </div>
            </NavigationView>
            <ConfirmDialog data={dialog} />
        </div>
    );

};

export default SurveyView;
