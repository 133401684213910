import React, { useState, useEffect } from 'react';


const UTCClock = () => {

  const formatUtcTime = (date) => {
   // Convert to EST by using 'America/New_York' timezone
   const estDate = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true // Ensures AM/PM format
}).formatToParts(date).reduce((acc, part) => {
    if (part.type === 'month') acc.mm = part.value;
    if (part.type === 'day') acc.dd = part.value;
    if (part.type === 'year') acc.yyyy = part.value;
    if (part.type === 'hour') acc.hh = part.value;
    if (part.type === 'minute') acc.min = part.value;
    if (part.type === 'second') acc.ss = part.value;
    if (part.type === 'dayPeriod') acc.ampm = part.value;
    return acc;
}, {});

// Return formatted date string in MM/DD/YYYY hh:mm:ss AM/PM
return `${estDate.mm}/${estDate.dd}/${estDate.yyyy} ${estDate.hh}:${estDate.min}:${estDate.ss} ${estDate.ampm}`;
  };


  const [utcTime, setUtcTime] = useState(formatUtcTime(new Date()));

  useEffect(() => {
    const interval = setInterval(() => {
      setUtcTime(formatUtcTime(new Date()));
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  
  return <span>{utcTime}</span>;
};


export default UTCClock;
