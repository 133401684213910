import styles from './style.module.css';

const Loader = ({
  text = "Loading..."
}) => (
  <div className={styles.loaderContainer}>
    <div className={styles.spinner}></div>
    <p className={styles.text}>{text}</p>
  </div>
);
export default Loader;